// import store from "./index"

let btnsMap = {}
function getPathList(data) {
    // console.log(data)
    data.forEach(item => {
       if(item.child && item.child.length){
            getPathList(item.child);
            if(item.menu_type === 'auth'){
                btnsMap[item.route] = {
                    'name': item.title, 'route': item.route
                }
            }
       }else{
            if(item.menu_type === 'auth'){
                btnsMap[item.route] = {
                    'name': item.title, 'route': item.route
                }
            }
       }
    //     if(item.permissionName.indexOf('（') >-1) {
    //         item.permissionName = item.permissionName.replace(/\（/, '(')
    //         item.permissionName = item.permissionName.replace(/\）/, ')')
    //     }
    //     if (item.children && item.children.length) {
    //         getPathList(item.children);
    //         pathSet.push(item.path);
    //         if (parseInt(item.permissionType) > 1) {
    //             btnsMap[item.permissionCode] = {
    //                 'name': item.permissionName, 'portalPermissionID': item.portalPermissionID
    //             }
    //         }
    //     } else {
    //         pathSet.push(item.path);
    //         if (parseInt(item.permissionType) > 1) {
    //             btnsMap[item.permissionCode] = {
    //                 'name': item.permissionName, 'portalPermissionID': item.portalPermissionID
    //             }
    //         }
    //     }
    });
}
export { getPathList,btnsMap }