import ajax from '../utils/request'
const loginApi = {
  /********************************登陆管理*********************************** */
  // 登陆
  login(params) {
    return ajax({
      url: '/api/auth/admin/login',
      type: 'post',
      params: params
    })
  },
  getUserInfo(params) {
    return ajax({
      url: '/api/auth/admin/verify',
      type: 'post',
      params
    })
  },
  getMenuListown() {
    return ajax({
      url: '/api/auth/admin/menu/ownMenu',
      type: 'get'
    })
  },
  chgPwdMenuList(params) {
    return ajax({
      url: '/api/auth/admin/chgPwd',
      type: 'post',
      params
    })
  },
}
export default loginApi
