import ajax from '../utils/request'
const homeApi = {
  /********************************首页*********************************** */
  // 登陆
  getStatData(params) {
    return ajax({
      url: '/home/statData',
      type: 'get',
      params: params
    })
  },
  // 上传图片接口
  uploadImg(param) {
    return ajax({
      url: '/upload/image',
      type: 'post',
      params: param
    })
  },
}
export default homeApi
