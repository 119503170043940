<template>
  <div class="video-box">
    <div id="dplayer" class="dplayer" ref="dplayer"></div>
  </div>
</template>

<script>
export default {
  name: 'VideoPlayer',
  props:{
    url: String
  },
  data(){
    return {
      dPlayer: null
    }
  },
  mounted() {
    window.Hls = require('hls.js');
    this.init()
  },
  methods: {
    // 初始化播放器
    async init(){
      const dp = await import('dplayer');
      const _DPlayer = dp.default;
      this.dPlayer = new _DPlayer({
        container: this.$refs.dplayer,
        video: {
          url: this.url,
          type: 'hls'
        },
      });
    },
    beforeDestroy() {
      if(this.dPlayer){
        this.dPlayer.destroy();
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.video-box {
  height: 100%;
  width: 100%;
  .dplayer {
    height: 100%;
    width: 100%;
    border-radius: 4px;
  }
}
</style>
