/*
 * @Author: 王枫叶
 * @Date: 2020-10-30 14:43:04
 * @LastEditors: 王枫叶
 * @LastEditTime: 2020-12-18 11:31:13
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./stroe";
import ElementUI from "element-ui";
import Router from "vue-router";
import filters from "@/utils/filter";
import "./assets/scss/element-variables.scss";
import "video.js/dist/video-js.css";
import "./permission";
import components from "@/utils/gloabalComponents";
import uploader from "vue-simple-uploader";
import VueCropper from "vue-cropper";
import AudioPlayer from '@liripeng/vue-audio-player'
import refreshbtn from "@/components/refreshbtn"
const directive = require("@/utils/directive");
Vue.use(directive);
Vue.component('refreshbtn',refreshbtn)
// import '@liripeng/vue-audio-player/lib/vue-audio-player.css'
Vue.prototype.$store = store;
ElementUI.Table['props'].border = { type:Boolean,default:true}
ElementUI.TableColumn['props'].showOverflowTooltip = {
  type: Boolean,
  default: true
};
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;

ElementUI.Select['props'].filterable = { type: Boolean, default: true };
ElementUI.Select['props'].clearable = { type: Boolean, default: true };
Vue.prototype.pageSize = [10,20,30,40];

// 引入lodash
import _ from "lodash";
Vue.prototype._ = _;
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
// 注册全局过滤器
Object.keys(filters).forEach((k) => Vue.filter(k, filters[k]));

// 全局组件注册
Vue.use(components);
// 图片裁剪组件注册
Vue.use(VueCropper);
// 全局路由
Vue.use(Router);
// 引入element-ui
Vue.use(ElementUI);
// 引入分片上传
Vue.use(uploader);
//引入音频插件
Vue.use(AudioPlayer);
//阻止启动生产模式的消息
Vue.config.productionTip = false;

const vue = new Vue({
  router,
  store, // 注册store选项  把 store 对象提供给 “store” 选项，实现把 store 的实例注入所有的子组件
  render: (h) => h(App), //生成VNodes树<=>虚拟DOM
}).$mount("#app");

export default vue;
