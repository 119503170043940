/**
 * @description 本地数据持久化操作，用来记录、读取、删除用户等公共数据
 */
const local = {
  // 设置键值对
  set(name, data) {
    data = JSON.stringify(data);
    localStorage.setItem(name, data);
  },
  // 获取键值
  get(name) {
    let data = localStorage.getItem(name);
    if (data) {
      return JSON.parse(data);
    } else {
      return null;
    }
  },
  // 删除某个键
  remove(name) {
    localStorage.removeItem(name);
  },
};

export default local;
