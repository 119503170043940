<template>
  <el-container class="main" direction="vertical">
    <!--头部-->
    <dg-header></dg-header>
    <el-container class="content" style="height: calc(100% - 60px)">
      <!--导航-->
      <dg-nav></dg-nav>
      <!--中间展示区域-->
      <dg-content></dg-content>
    </el-container>
  </el-container>
</template>

<script>
import DgHeader from './components/header'
import DgNav from './components/nav'
import DgContent from './components/content'
export default {
  components: {
    DgHeader,
    DgNav,
    DgContent
    
  }
}
</script>

<style lang="scss">
.main {
  width: 100%;
  height: 100%;
  overflow: hidden;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ccc;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
}
</style>
