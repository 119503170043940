import VideoList from '../views/videoManagement/videoList'
import Main from '../views/main'

const VideoListRouter = {
  path: '/video-examine',
  component: Main,
  title: '媒体管理',
  icon: 'el-icon-video-camera-solid',
  meta: {
    keepAlive: true
  },
  children: [
    {
      path: '/video-list',
      component: VideoList,
      name: 'VideoList',
      title: '视频列表',
      meta: {
        title: '视频列表',
        keepAlive: true
      }
    }
  ]
}

export default VideoListRouter
