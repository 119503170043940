import ajax from '../utils/request'
const adminApi = {
  /********************************acg相关接口*********************************** */
  getAcgListData(params) {
    return ajax({
      url: '/api/media/list',
      type: 'get',
      params
    })
  },
  getAcgDetailData(params) {
    return ajax({
      url: '/api/media/content/list',
      type: 'get',
      params
    })
  },
  reloadDetailData(params) {
    return ajax({
      url: '/api/media/content/checkRS',
      type: 'post',
      params
    })
  },
  acgDeleteList(params) {
    return ajax({
      url: '/api/media/batch/delete',
      type: 'post',
      params
    })
  },
  acgDetailDeleteList(params) {
    return ajax({
      url: '/api/media/content/batch/delete',
      type: 'post',
      params
    })
  },
  acgUpdateList(params) {
    return ajax({
      url: '/api/media/batch/update',
      type: 'post',
      params
    })
  },
  acgDetailUpdateList(params) {
    return ajax({
      url: '/api/media/content/batch/update',
      type: 'post',
      params
    })
  },
  acgDataUpdate(params) {
    return ajax({
      url: '/api/media/update',
      type: 'post',
      params
    })
  },
  acgDetailUpdate(params) {
    return ajax({
      url: '/api/media/content/update',
      type: 'post',
      params
    })
  },
  uploadMp3File(params){
    console.log(params)
    return ajax({
        url: '/api/general/system/uploadSingleFile',
        type: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        params
    })
    },
}
export default adminApi