<template>
  <div class="img-wrap">
    <el-image :src="decyImg" class="cover" :style="{width: width, height: height}" @click="checkMedia(decyImg,'img')">
      <!--加载时触发-->
      <div slot="placeholder" class="image-slot" :style="{width: width, height: height, lineHeight: height}">
        <i class="el-icon-loading"></i>加载中
      </div>
    </el-image>
    <!--封面图、视频弹窗组件-->
    <media-popup ref="mediaPopup" ></media-popup>
  </div>
</template>
<script>
import MediaPopup from '@/components/media-popup'
import baseConfig from "@/utils/serverUrlConfig";
import API from "@/api";
export default {
  components: {
    MediaPopup,
  },
  props: ['width', 'height', 'src', "disableCheck"],
  data() {
    return {
      decyImg: "",
      imgUrl: baseConfig.decyptImgUrl,
    }
  },
  created() {
    try {
      API.imgDecyptApi(this.imgUrl + this.src).then((url) => {
        this.decyImg = url;
      });
    } catch (error) {
      console.log(error);
    }
    
    // console.log(API)
  },
  methods: {
    // 查看媒体
    checkMedia(url, type) {
      if (this.disableCheck) {
        return;
      }
      this.$refs.mediaPopup.open(url, type)
    },

  }
} 
</script>

<style lang="scss">
.img-wrap {
  display: flex;
  justify-content: center;
  cursor: pointer;
  .image-slot {
    background: #f5f7fa;
    font-size: 12px;
    color: #c0c4cc;
  }
  .el-image__error {
    font-size: 12px;
  }
}
</style>