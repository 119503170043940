// import Api from '../../api'
// import vue from '@/main'

const state = {
  token: "",
  links: [],
  // 后端返回的用户信息
  userInfo: {},
  // 前端登陆保存的登陆信息
  loginInfo: {},
};

const getters = {
  getLinks: (state) => {
    return state.links;
  },
};

const mutations = {
  // 保存token到vuex中
  COMMIT_TOKEN(state, object) {
    state.token = object;
  },
  // 保存用户信息到vuex中
  COMMIT_USERINFO(state, obj) {
    state.userInfo = Object.assign({}, obj);
    state.links = state.userInfo.inviteLink;
  },
  // 保存登陆信息到vuex中
  COMMIT_LOGININFO(state, object) {
    state.loginInfo = object;
  },
  COMMIT_USERINFO_BALANCE(state, balance) {
    state.userInfo.balance = balance;
  },
  COMMIT_USERINFO_MOBILE(state, mobile) {
    state.userInfo.mobile = mobile;
  },
  // 登出重置用户信息，token，渠道信息
  COMMIT_LOGOUT(state) {
    state.userInfo = {};
    state.token = "";
  },
  // 设置链接信息
  SET_LINK(state, linkarr) {
    state.links = linkarr;
  },
};

const actions = {
  setToken({ commit }, obj) {
    commit("COMMIT_TOKEN", obj);
  },
  setBalance({ commit }, balance) {
    commit("COMMIT_USERINFO_BALANCE", balance);
  },
  setMobile({ commit }, mobile) {
    commit("COMMIT_USERINFO_MOBILE", mobile);
  },
  // adminLogin({ commit }, params) {
  //   return new Promise((resolve, reject) => {
  //     Api.login(params).then(res => {
  //       if (res.code === 200 && res.data.token && res.data.channels) {
  //         commit('COMMIT_TOKEN', res.data)
  //         commit('COMMIT_USERINFO', {
  //           name: res.data.name,
  //           account: res.data.account,
  //           // role: res.data.role
  //         })
  //         resolve(res)
  //       } else {
  //         vue.$message.error('账号或密码错误，请重新登陆')
  //         reject()
  //       }
  //     })
  //   })
  // },
  saveLoginInfo({ commit }, loginInfo) {
    commit("COMMIT_LOGININFO", loginInfo);
  },
  saveUserInfo({ commit }, userInfo) {
    commit("COMMIT_USERINFO", userInfo);
  },
  logout({ commit }) {
    commit("COMMIT_LOGOUT");
  },
  setLink({ commit }, linkArr) {
    commit("SET_LINK", linkArr);
  },
};

const login = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default login;
