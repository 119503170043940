import ajax from '../utils/request'
const rolesApi = {
    /********************************角色相关接口*********************************** */
    getRolesList(params) {
        return ajax({
            url: `/api/auth/admin/role/list`,
            type: 'get',
            params
        })
    },
    // 添加角色
    createRolesList(params) {
        return ajax({
            url: `/api/auth/admin/role/create`,
            type: 'post',
            params
        })
    },
    // 删除角色
    destoryRolesList(params) {
        return ajax({
            url: `/api/auth/admin/role/destroy`,
            type: 'post',
            params
        })
    },
    // 更新角色
    updataRolesList(params) {
        return ajax({
            url: `/api/auth/admin/role/update`,
            type: 'post',
            params
        })
    },

    // 获取角色权限
    getRbacRoles(params) {
        return ajax({
            url: `/api/auth/rbac/list`,
            type: 'get',
            params
        })
    },
}

export default rolesApi