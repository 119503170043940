const state = {
  routerTags: [],
  routerIndex: 0,
  activeRouter: "",
  routerList: [],
  token: "",
  beilv: "",
  confirmFlag: true,
  actJson: "",
};
export default state;
