import { paramsFilter } from "@/utils/global";
import axios from "axios";
import Api from "../api";
import vue from "../main";
// import store from "../stroe/index";
import local from "./localStroage.js";
import config from "./serverUrlConfig";

// 创建一个axios实例
const instance = axios.create({
  baseURL: config.baseServerUrl,
  timeout: 300000,
});

// 给实例添加一个setToken方法，用于登录后将最新token动态添加到header，同时将token保存在localStorage中
instance.setToken = (token) => {
  instance.defaults.headers["Token"] = token;
  local.setItem("token", token);
};
instance.defaults.headers.post["Content-Type"] =
  "application/json; charset=utf-8";

function refreshToken() {
  // instance是当前request.js中已创建的axios实例
  return Api.refreshToken().then((res) => res);
}
function exit() {
  local.remove("userInfo");
  local.remove("role");
  local.remove("UserMenu");
  local.remove("btnPower");
  vue.$store.dispatch("removeAllTag");
  vue.$store.dispatch("setConfirmFlag");
  vue.$router.push("/login");
}

// 是否正在重新登陆
// let isLogining = false;
// 下次调用接口需要刷新的标识
let needRefresh = false;
// 是否正在刷新的标记
let isRefreshing = false;
// 重试队列，每一项将是一个待执行的函数形式
let requests = [];

// 设置请求拦截器，将token传入header传递给后端
instance.interceptors.request.use(
  (config) => {
    let token = local.get("userInfo") ? local.get("userInfo").token : "",
      storeToken = vue.$store.state.token;
    if (storeToken !== token) {
      vue.$store.dispatch("setToken", token);
    }
    if (token) {
      config.headers["Token"] = token;
    }
    if (config.url.endsWith("refresh")) {
      return config;
    }
    if (needRefresh) {
      if (!isRefreshing) {
        // eslint-disable-next-line no-const-assign
        isRefreshing = true;
        return refreshToken()
          .then((res) => {
            const { token } = res.data;
            let userInfo = local.get("userInfo") ? local.get("userInfo") : {};
            userInfo = Object.assign(userInfo, {
              token: token,
            });
            vue.$store.dispatch("setToken", token);
            local.set("userInfo", userInfo);

            config.headers["Token"] = token;
            isRefreshing = false;
            needRefresh = false;
            // 已经刷新了token，将所有队列中的请求进行重试
            requests.forEach((cb) => cb(token));
            requests = [];
            return config;
          })
          .catch((res) => {
            window.console.log("refreshtoken error =>", res);
            isRefreshing = false;
            needRefresh = false;
          })
          .finally(() => {
            //console.log('刷新事件结束')
          });
      } else {
        // 正在刷新token，将返回一个未执行resolve的promise
        return new Promise((resolve) => {
          // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
          requests.push((token) => {
            config.headers["Token"] = token;
            resolve(instance(config));
          });
        });
      }
    }
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.data.type === "application/json") {
      let reader = new FileReader();
      reader.readAsText(response.data, "utf-8");
      reader.onload = (e) => {
        let res = JSON.parse(e.target.result);
        if (res.code != 200) {
          vue.$message.error(res.tip);
          return;
        }
      };
    } else {
      if (
        response.headers["refresh-authorization"] === "true" &&
        !isRefreshing
      ) {
        needRefresh = true;
      }
      switch (response.data.code) {
        case 1006:
          vue.$message.error("您的帐号已被禁用，请联系管理员");
          exit();
          break;
        case 4000:
          vue.$message.error("登陆过期");
          exit();
          break;
        case 5009:
          vue.$message.error("无效token");
          exit();
          break;
        // case 5009:
        //   if (!isLogining) {
        //     if (store.state.confirmFlag) {
        //       vue
        //         .$confirm("登陆信息已失效,请重新登陆!", "退出提示", {
        //           confirmButtonText: "确定",
        //           cancelButtonText: "取消",
        //           type: "warning",
        //         })
        //         .then(() => {
        //           isLogining = true;
        //           local.remove("userInfo");
        //           local.remove("role");
        //           local.remove("UserMenu");
        //           local.remove("btnPower");
        //           vue.$store.dispatch("removeAllTag");
        //           vue.$store.dispatch("setConfirmFlag");
        //           vue.$router.push("/login");
        //         })
        //         .catch(() => {
        //           return Promise.reject(new Error("取消返回登陆页面"));
        //         })
        //         .finally(() => {
        //           isLogining = false;
        //         });
        //     }
        //   }
        //   break;
        case 200:
          return Promise.resolve(response.data);
        default:
          if (response.data.tip) {
            vue.$message.error(response.data.tip);
            throw new Error(response.data.tip);
          } else {
            return Promise.resolve(response.data);
          }
        //case 200:
        //  return Promise.resolve(response.data)
        //default:
        //  vue.$message.error(response.data.tip)
        //  return Promise.reject(response.data.tip)
      }
      return Promise.resolve(response.data);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

const ajax = (param, noFiltering) => {
  // 定义内部默认请求对象
  let defaultParam = {
    url: "",
    type: "get",
    params: {},
  };
  if (
    param.url !== "/api/general/system/uploadImage" &&
    param.params &&
    !noFiltering
  ) {
    param.params = paramsFilter(param.params);
  }
  // 合并请求参数
  let newParams = Object.assign(defaultParam, param);
  // 设置公共请求地址、超时时间
  defaultParam.url = config.baseServerUrl + defaultParam.url;
  instance.defaults.timeout = 1000 * 30;
  // 返回一个ajax对象
  return new Promise((resolve, reject) => {
    // 处理post、get不同请求时参数处理
    let getParams, postParams;
    if (newParams.type === "get") {
      getParams = newParams.params;
    } else {
      postParams = newParams.params;
    }

    let intanceObject = {
      url: newParams.url,
      method: newParams.type,
      data: postParams,
      params: getParams,
      responseType: newParams.responseType,
      headers: param.headers,
    };
    if (newParams.onUploadProgress) {
      intanceObject.onUploadProgress = newParams.onUploadProgress;
    }
    if (newParams.timeout) {
      intanceObject.timeout = newParams.timeout;
    }
    instance(intanceObject)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
export default ajax;
