import Vue from "vue";
import Router from "vue-router";

// 引入公共路由
import baseRouter from "./baseRouter";

Vue.use(Router);

// 实例化vue-router
const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: baseRouter,
});
export default router;
