<template>
  <div class="upload-imgs-box">
    <el-upload
      ref="upload"
      class="upload-demo"
      :action="uploadUrl"
      name="upload[]"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :on-success="handleAvatarSuccess"
      :on-error="uploadError"
      :before-upload="beforeAvatarUpload"
      accept="image/jpeg, image/png, image/jpg"
      :on-exceed="handleExceed"
      list-type="picture-card"
      multiple
      :limit="150"
      :file-list="fileList"
    >
      <el-button size="small" class="btnPics" type="success" @click="submitUpload">选取图片</el-button>
      <!-- <el-button size="small" type="success" @click="submitUpload">上传</el-button> -->
      <div slot="tip" v-if="!notLimit">只能上传jpg、png、gif格式，大小不超过500KB的图片</div>
    </el-upload>
  </div>
</template>
<script>
import baseConfig from "@/utils/serverUrlConfig";
import local from "@/utils/localStroage";
export default {
  data() {
    return {
      fileList: [],
      imgUrl: baseConfig.decyptImgUrl,
      uploadUrl: baseConfig.baseServerUrl + "/vid/uploadStaticBatch",
      cropperModel: false,
      // 当前选中的文件（未压缩裁剪之前）
      checkedFile: "",
      header: {
        Token: local.get("userInfo").token,
      },
    };
  },
  props: ["cover", "width", "height", "id", "wSize", "hSize", "hide", "notLimit"],
  methods: {
    //移除图片
    // eslint-disable-next-line no-unused-vars
    handleRemove(file, fileList) {},
    // eslint-disable-next-line no-unused-vars
    handlePreview(file) {},
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 150 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    // eslint-disable-next-line no-unused-vars
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 图片上传前验证
    beforeAvatarUpload(file) {
      const isLt3M = file.size / 1024 / 1024 < 0.1;
      if (!isLt3M && !this.notLimit) {
        this.$message.error("图片上传大小不能超过100kb");
        return false;
      }
    },
    //图片上传成功回调
    // eslint-disable-next-line no-unused-vars
    handleAvatarSuccess(response, file, fileList) {
      this.SeriesCover = [];
      this.$refs.upload.uploadFiles.forEach((e) => {
        window.console.log(this.$refs.upload.uploadFiles,'这是什么？？？')
        if (e.response) {
          // 处理后台返回的值
          this.SeriesCover.push(e.response.data.filePath[0]);
        }
      });   
      this.$emit("successCallback",this.SeriesCover);
    },
    //图片上传错误
    uploadError() {
      this.$message.error("上传封面图失败");
    },
    submitUpload () {
      let { uploadFiles } = this.$refs.upload
      let form = new FormData()
      let status = "success"
      // 在这里对每一张图片进行大小的校验，如果不符合则提示，所有不符合的都提示，校验完成后只要有不符合条件的就不执行下面的操作
      uploadFiles.forEach(item => {
       const size = item.raw.size / 1024 <= 500
       if (!size && !this.notLimit) {
        this.$message.error(`${item.raw.name}大小超过500KB`)
        status = "failed"
        return
       }
       form.append('upload[]', item.raw)
      })
      if (!status) {
       return
      }
      // 符合条件后再将这个FormData对象传递给后端
      //调取接口上传form参数
    },

    // 只要列表中状态改变on-change就会被调用
    uploadChange (file, fileList) {
      window.console.log(fileList)
      if (this.$refs.upload.uploadFiles.filter(i =>{ return i.status !== "success" }).length === 0) {
        fileList = this.SeriesCover
        this.fileList = [...this.fileList, ...this.SeriesCover]
      }
    },

    ImageRequest (file) {
      this.formData.append('upload[]', file.file)
    },

    // 清除图片
    clearImg() {
      this.$refs.upload.clearFiles();
      // window.console.log(this.fileList)
    },

    // 获取回显图片
    getFileList(imgArr) {
      this.fileList = []
      imgArr.forEach(i => {
        this.fileList.push({url: this.imgUrl + i})
      })
    }
  },
};
</script>
<style lang="scss">
.upload-imgs-box {
  width: 100%;
  .el-upload {
    width: 200px !important;
    height: 100px !important;
    .btnPics {
      position: relative;
      top: -25px;
      left: 0px;
    }
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 100px !important;
    height: 100px !important;
  }
}
</style>

