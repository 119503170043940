<template>
  <div>
    <el-dialog
      :title="title"
      align="center"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
    >
      <div class="media-box" id="media-box" v-if="videoShow">
        <VideoPlayer :url="mediaUrl"></VideoPlayer>
      </div>
      <div class="media-box" id="img-box" v-if="imgShow">
        <img :src="mediaUrl" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import "videojs-contrib-hls";
import VideoPlayer from "@/components/video-player";
export default {
  data() {
    return {
      dialogVisible: false,
      imgShow: true,
      mediaUrl: "",
      videoShow: false,
      title: "封面图查看",
      myData: []
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    }
  },
  components: {
    VideoPlayer
  },
  mounted() {},
  methods: {
    handleClose() {
      if (this.player) {
        this.player.dispose();
      }
      this.dialogVisible = false;
      this.imgShow = false;
      this.videoShow = false;
      this.mediaUrl = "";
    },
    open(url, type) {
      this.dialogVisible = true;
      if (type === "img") {
        this.imgShow = true;
        this.videoShow = false;
        this.title = "封面图查看";
        this.mediaUrl = url;
      } else {
        this.imgShow = false;
        this.videoShow = true;
        this.title = "视频查看";
        this.mediaUrl = url;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.my_data {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.media-box {
  width: 100%;
  height: 400px;
  text-align: center;
  overflow: hidden;
  img {
    height: 100%;
    max-width: 100%;
  }
  #my-video {
    width: 100% !important;
    height: 100% !important;
    border: none !important;
  }
}
</style>