<template>
  <div class="upload-img-box">
    <el-upload
      class="avatar-uploader"
      action="uploadUrl"
      :show-file-list="false"
      :before-upload="beforeAvatarUpload"
      :on-success="handleAvatarSuccess"
      :on-error="uploadError"
      accept="image/jpeg, image/png, image/jpg"
      :style="{width: width,height: height}"
      ref="multipleUpload"
      :http-request="uploadPlans"
      list-type="picture"
      :headers="header"
      :data="id ? {id: id}: {}">
      <img-load v-if="cover" :src="cover" :disableCheck="true" class="avatar" :key="cover" />
      <i v-else class="el-icon-plus avatar-uploader-icon" :style="{lineHeight: height}"></i>
    </el-upload>
    <p class="desc" v-if="isShow">图片大小最好不要超过200kb,且宽高度最好为{{this.wSize + '*' + this.hSize}}</p>
  </div>
</template>
<script>
import baseConfig from "@/utils/serverUrlConfig";
import local from "@/utils/localStroage";
import api from '@/api'
export default {
  data() {
    return {
      imgUrl: baseConfig.decyptImgUrl,
      uploadUrl: baseConfig.baseServerUrl + "/upload/image",
      cropperModel: false,
      // 当前选中的文件（未压缩裁剪之前）
      checkedFile: "",
      // 传递到裁剪框中的图片路径
      imgFile: "",
      file_name: "",
      header: {
        Token: local.get("userInfo").token
      },
      lockImg: '',
    };
  },
  props: ["cover", "width", "height", "id", "wSize", "hSize", "hide"],
  computed: {
    isShow() {
      return this.hide ? false : true;
    }
  },
  methods: {
    // 图片上传前验证  
    async beforeAvatarUpload(file) {
      const isLt3M = file.size / 1024 / 1024 < 0.1;
      if (!isLt3M) {
        this.$message.error("封面上传大小不能超过100kb");
        return false
      }
      this.file_name = file.name;
      this.imgFile =  await this.getBase64(file)
    },
    // 视频封面上传成功回掉
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        this.$emit("successCallback", res);
      } else {
        this.$message.error("上传封面图失败");
      }
    },
    // 上传文件
    uploadPlans() {
      if (this.imgFile === "") {
        this.$message.error('请选择图片后上传')
        return
      }
      this.loading = true
      var form = {
        "img_data": this.imgFile.split(",")[1],
        "file_name": this.file_name,
      }
      api.uploadImg(form).then((res) => {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '上传成功!'
          })
          this.lockImg = res.data.result.url
          this.changeImgUrl(res)
        } else {
          this.$message({
            type: 'error',
            message: '上传失败!'
          })
          this.lockImg = ''
        }
      })
    },

    changeImgUrl(res) {
      this.$emit('changeImgUrl', this.lockImg)
      this.$emit("successCallback", res);
    },

    // 转base64码
    async getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        let fileResult = ''
        reader.readAsDataURL(file)
        // 开始转
        reader.onload = () => {
          fileResult = reader.result
        }
        // 转 失败
        reader.onerror = (error) => {
          reject(error)
        }
        // 转 结束
        reader.onloadend = () => {
          this.file_name = file.name
          resolve(fileResult)
        }
      })
    },
    
    // 图片上传错误
    uploadError() {
      this.$message.error("上传封面图失败");
    }
  }
};
</script>
<style lang="scss">
.dialog-popup {
  .el-dialog {
    margin-top: 0px !important;
  }
}
.upload-img-box {
  .avatar {
    width: 100%;
    height: 100%;
  }
  .avatar-uploader {
    width: 150px;
    height: 150px;
  }
  .el-icon-plus {
    height: 100% !important;
  }
  img {
    width: 100%;
    height: 100%;
  }

  .el-upload {
    width: 100%;
    height: 100%;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border: 1px dashed #e63765;
    }
  }
  .desc {
    line-height: 1;
    margin-top: 10px;
    color: #999;
    font-size: 12px;
  }
  .avatar-uploader-icon {
    width: 100%;
    height: 100%;
    font-size: 28px;
    color: #8c939d;
    line-height: 150px;
    text-align: center;
    &:hover {
      color: 1px dashed #e63765 !important;
    }
  }
}
</style>

