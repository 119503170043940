import ajax from '../utils/request'
const adminApi = {
  /********************************管理员相关接口*********************************** */
  getAdminStratorsList(params) {
    return ajax({
      url: '/api/auth/admin/user/list',
      type: 'get',
      params
    })
  },
  createAdminStrators(params) {
    return ajax({
      url: '/api/auth/admin/user/create',
      type: 'post',
      params
    })
  },
  updateAdminStrators(params) {
    return ajax({
      url: '/api/auth/admin/user/update',
      type: 'post',
      params
    })
  },
  destoryAdminStrators(params) {
    return ajax({
      url: '/api/auth/admin/user/destroy',
      type: 'post',
      params
    })
  },
}
export default adminApi