import ajax from '../utils/request'
const baseApi = {
    /********************************标签相关接口*********************************** */
    getConfigList(params) {
        return ajax({
            url: '/api/general/config/list',
            type: 'get',
            params
        })
    },
    createConfig(params){
        return ajax({
            url:'/api/general/config/create',
            type:'post',
            params
        })
    },

    updateConfig(params){
        return ajax({
            url:'/api/general/config/update',
            type:'post',
            params
        })
    },

    deleteConfig(params){
        return ajax({
            url:'/api/general/config/destroy',
            type:'post',
            params
        })
    },

}
export default baseApi
