import ajax from '../utils/request'
const videoApi = {
  /********************************审核视频相关接口*********************************** */
  getVideoList(params) {
    return ajax({
      url: '/creator/video',
      type: 'get',
      params: params
    })
  },
  async imgDecyptApi (url) {
    let encoder = new TextEncoder();
    let KEY = encoder.encode("2019ysapp7527");
    return new Promise(resolve => {
        let oReq = new XMLHttpRequest();
        oReq.open("GET", url, true);
        oReq.responseType = "arraybuffer";
        oReq.onload = function () {
            var arrayBuffer = oReq.response; // 注意:不是oReq.responseText
            if (arrayBuffer) {
                var bytes = new Uint8Array(arrayBuffer);
                for (let i = 0; i < 100; i++) {
                    bytes[i] ^= KEY[i % KEY.length]
                }
                const blob = new Blob([bytes], {
                    type: "image/png"
                })
                const url = URL.createObjectURL(blob)
                resolve(url)
            }
        };
        oReq.send("");
    }).catch(() => {
        console.log("解密失败");
    })
  }
}
export default videoApi