<template>
  <div class="container">
    <div class="left" :key="index" v-for="(item,index) in baseData">
      <span>键名:</span>
      <el-input v-model="item.key"></el-input>
      <span>键值:</span>
      <el-input v-model="item.value"></el-input>
      <el-button type="danger"  style="margin-right: 10px" v-show="baseData.length>1" @click="deleteData(item)">X</el-button>
      <el-button type="success" @click="addData" icon="el-icon-plus
">追加
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddFormItem",
  props:{
    baseData:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  data() {
    return {

    }
  },
  methods:{
    addData(){
      let value=this._.cloneDeep(this.baseData)
      console.log("value",value)
      value.push({key:'',value:''})
      this.$emit('update:baseData',value)
      console.log("this.baseData",this.baseData)
    },
    deleteData(data){
      let value=this._.cloneDeep(this.baseData)
      const index= value.findIndex(item=>{
       return  item.key==data.key
      })
      value.splice(index,1)
      this.$emit('update:baseData',value)
      console.log("this.baseData",this.baseData)
    }
  }
}
</script>

<style scoped lang="scss">
.container {

  .left {
    display: flex;
    white-space: nowrap;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    span {
      margin-right: 10px;
    }

    .el-input {
      margin-right: 10px;
    }
  }

}

</style>
