import Login from '../views/login'
import Main from '../views/main'
import testAddFormItem from "@/views/demo/testAddFormItem";

// 不需要权限控制的路由
const baseRouter = [
  {
    path: '/',
    component: Login
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/testAddFormItem',
    name: 'testAddFormItem',
    component: testAddFormItem
  },
  {
    path: '/main',
    component: Main,
    redirect: '/welcome',
    title: '',
    icon: '',
    children: [
      {
        path: '/welcome',
        component: () => import('@/views/welcome/index'),
        name: 'welcome',
        title: '欢迎页',
        icon: ''
      }
    ]
  }
]
export default baseRouter
