import router from "./router";
import store from "./stroe";
import local from "@/utils/localStroage.js";
import authRouter from "./router/permissionRouter.js";
/**
 * @description 设置全局路由守卫，判断用户是否登陆
 * @param to 开始路由
 * @param form 目的路由
 * @param next 做拦截处理
 * @public
 */
router.beforeEach((to, form, next) => {
  let userInfo = local.get("userInfo");
  // 获取当前用户权限
  let userRouter = authRouter;
  // 判断用户是否登陆
  if (userInfo) {
    if (to.path === "/welcome" && userRouter) {
      store.dispatch("refreshRouter");
      next();
      console.log(4, to);
      return false;
    }
    // 在登陆状态下跳转到登录页面，则自动跳转到当前页
    if (to.path === "/login" || to.path === "/") {
      next({
        path: "/welcome",
      });
      // 移除所有的标签
      store.dispatch("removeAllTag");
    } else {
      // 用户没有页面权限时，直接跳转到欢迎页
      if (userRouter.length === 0) {
        next({
          path: "/welcome",
        });
      }
      // 用户有页面权限，判断路由是否存在，不存在则实时刷新路由
      let hasRoles =
        store.state.routerList && store.state.routerList.length > 0;
      if (hasRoles) {
        next();
      } else {
        // 用户刷新后，vuex的数据被清空实时刷新数据，从locastorage中获取数据并刷新。
        store.dispatch("refreshRouter");
        // 重定向到该页面
        next({
          ...to,
          replace: true,
        });
      }
      store.dispatch("setRouterActive", to.path);
    }
  } else {
    if (to.path !== "/login" && to.path !== "/") {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  }
});
