import router from "../router";
// import { createRouter } from '@/utils/global'
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  REMOVE_TOKEN: (state) => {
    state.token = "";
  },
  // 设置面包屑路由tag和数量
  SET_TAGS: (state, tag) => {
    state.routerTags.push(tag);
    if (state.routerTags.length > 11) {
      state.routerTags.shift();
    }
    state.routerIndex = state.routerTags.length;
  },
  // 设置当前被选中的路由tags
  SET_INDEX: (state, index) => {
    state.routerIndex = index;
  },

  // 设置模拟倍率
  SET_BEILV: (state, beilv) => {
    state.beilv = beilv;
  },

  // 动态设置导航栏选中router
  SET_ROUTER_ACTIVE: (state, router) => {
    state.activeRouter = router;
  },
  // 删除全部tags
  REMOVE_ALL_TAGS: (state) => {
    state.routerTags = [];
  },
  // 删除路由tags
  REMOVE_TAGS: (state, index) => {
    if (state.routerTags.length === 1) {
      return;
    }
    state.routerTags.splice(index, 1);
    state.routerIndex = state.routerTags.length;
  },
  // 设置动态路由
  SET_ROUTER: (state, list) => {
    state.routerList = list;
    // router.matcher = createRouter([]).matcher
    router.addRoutes(list);
  },
  // 防止弹窗重复弹出
  SET_CONFIRM_FLAG: (state) => {
    state.confirmFlag = false;
  },
  ACT_JSON: (state, actJson) => {
    state.actJson = actJson;
  },
};

export default mutations;
