import imgLoad from "@/components/imgLoad";
import imgsUpload from "@/components/imgsUpload";
import baseUpload from "@/components/baseUpload";
// import globalUploader from '@/components/globalUploader

import cropper from "@/components/cropper";
import addFormItem from "@/components/AddFormItem";
export default (Vue) => {
  Vue.component("ImgLoad", imgLoad);
  Vue.component("ImgsUpload", imgsUpload);
  Vue.component("BaseUpload", baseUpload);
  Vue.component("Cropper", cropper);
  Vue.component("AddFormItem", addFormItem);
  // Vue.component('GlobalUploader', globalUploader)
};
