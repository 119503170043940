import Vue from "vue";
Vue.directive("has", {
  inserted: function (el, binding) {
      let obj = JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('btnPower'))));
      let isHas = false;
      if (typeof binding.value != "object") {
        if (obj[binding.value]) {
          isHas = true;
          return isHas;
        }
      }
      if (!isHas) {
        el.disabled = true;
        el.classList.add("is-disabled");
        //删除el元素
        // el.parentNode.removeChild(el)
      }
  },
});
