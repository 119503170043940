import moment from "moment";
import VueRouter from "vue-router";
// 转换排序
const sortEscape = (string) => (string === "descending" ? 1 : -1);

//格式化时日期  时间类型参数发送给后端服务统一处理
const changeTime = (string) => {
  // 时间字符传 2019/10/01T12:00:00+08:00(后面时区固定为+08:00)
  let time = moment(string).format();
  let newTime = time.slice(0, 19) + "+08:00";
  //let newTime = moment(string).format('YYYY-MM-DD h:mm:ss')
  // 时间戳
  //let time = new Date(string)
  //let newTime = Date.parse(time) / 1000
  return newTime;
};
// 时间转换
const changeSumDate = (string) => {
  // 时间字符传 2019/10/01T12:00:00+08:00(后面时区固定为+08:00)
  let time = moment(string).format();
  let newTime = time.slice(0, 10) + "T00:00:00+08:00";
  return newTime;
};
// bj转换成当地时间
const changeSumDatejb = (string) => {
  // 时间字符传 2019/10/01T12:00:00+07:00(后面时区固定为+07:00)
  let time = moment(string).format();
  let newTime = time.slice(0, 10) + "T00:00:00+07:00";
  return newTime;
};
// 将北京时间去掉时区
const deleteDateZone = (time) => {
  let newTime = time.substr(0, time.length - 6);
  return newTime;
};
const changeMonth = (string) => {
  // 时间字符传 2019/10/01T12:00:00+08:00(后面时区固定为+08:00)
  const time = moment(string).format()
  const newTime = time.slice(0, 7)
  return newTime
}
const changeDate = (time) => {
  if (!time || time === "未知") {
    return "";
  }
  let date = moment
    .utc(time)
    .utcOffset(480)
    .format("YYYY-MM-DD HH:mm:ss");
  return date;
};
const changeDates = (time) => {
  if (!time || time === "未知") {
    return "";
  }
  let date = moment
    .utc(time)
    .utcOffset(480)
    .format("YYYY-MM-DD HH:mm:ss");
  return date;
};

// 参数过滤，空的参数不传
const paramsFilter = (obj) => {
  let params = {};
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (typeof value === "number" && value !== Infinity && !isNaN(value)) {
      params[key] = value;
    } else if (typeof value === "boolean" && value !== "") {
      params[key] = value;
    } else if (value === "ZERO_VALUE") {
      params[key] = "";
      return;
    } else if (value !== "" && value) {
      params[key] = value;
    } else {
      return;
    }
  });
  return params;
};
// 获取当前系统时间
const nowDate = () => {
  let date = new Date();
  let seperator1 = "-";
  let seperator2 = ":";
  let month = date.getMonth() + 1;
  let strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  let currentdate =
    date.getFullYear() +
    seperator1 +
    month +
    seperator1 +
    strDate +
    " " +
    date.getHours() +
    seperator2 +
    date.getMinutes() +
    seperator2 +
    date.getSeconds();
  return currentdate;
};

/**
 * @description 将本地存储的路由转换成vue能够识别的动态路由
 * @param userRouter 用户权限路由
 * @param allRouter 全局权限路由
 */
const recursionRouter = (userRouter = [], allRouter = []) => {
  let realRoutes = [];
  //   routerList = _.cloneDeep(allRouter),
  //   permList = _.cloneDeep(userRouter);
  if(allRouter&&userRouter){
    allRouter.forEach((v) => {
      userRouter.forEach((item) => {
        // let data = {};
        if (item.route === v.path) {
          // data = _.cloneDeep(v);
          // delete data.children;
          v.icon = item.icon
          if (item.child && item.child.length > 0) {
            v.children = recursionRouter(item.child, v.children);
          }
          realRoutes.push(v);
        }
      });
    });
  }

  return realRoutes;
};

const createRouter = (routes) =>
  new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
  });

export {
  sortEscape,
  changeDate,
  changeTime,
  paramsFilter,
  nowDate,
  recursionRouter,
  changeDates,
  deleteDateZone,
  createRouter,
  changeSumDate,
  changeMonth,
  changeSumDatejb
};
