import ajax from '../utils/request'
const menuApi = {
    /********************************菜单相关接口*********************************** */
    getMenuList(params) {
        return ajax({
            url: '/api/auth/admin/menu/list',
            type: 'get',
            params
        })
    },
    createMenu(params){
        return ajax({
            url:'/api/auth/admin/menu/create',
            type:'post',
            params
        })
    },
    destroyMenu(params){
        return ajax({
            url:'/api/auth/admin/menu/destroy',
            type:'post',
            params
        })
    },
    updateMenu(params){
        return ajax({
            url:'/api/auth/admin/menu/update',
            type:'post',
            params
        })
    },
    getOwnMenu(){
        return ajax({
            url:'/api/auth/admin/menu/ownMenu',
            type:'get',
        })
    },
}
export default menuApi
