<template>
  <div id="app">
    <keep-alive v-if="isLoggedIn">
      <router-view v-if="$route.meta.keepAlive" style="min-height:100%"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive ||!isLoggedIn" style="min-height:100%"></router-view>
  </div>
</template>
<script>
import local from "./utils/localStroage.js";

export default {
  data() {
    return {
      isLoggedIn: false
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // console.log(to,from)
      // if the route changes...
      let token = local.get("userInfo") ? local.get("userInfo").token : "";
      this.isLoggedIn = token ? true : false;

    }
  }
};
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
}
</style>
