/*
 * @Author: 王枫叶
 * @Date: 2020-10-13 17:37:19
 * @LastEditors: 王枫叶
 * @LastEditTime: 2020-12-17 21:44:43
 */
// 首页统计数据接口
import homeApi from './home';
// 登陆api接口
import loginApi from './login';
import manageUPAPI from "./manage";
// 视频api接口
import videoApi from './videoManagement';

// api接口
import classApi from './class';
import projectApi from './project';
import adminApi from './administrators';
import menuApi from './menu';
import terminalApi from './terminal';
import rolesApi from './roles';
import acgApi from './acg';
import baseConfig from './baseConfig';
import mediumApi from './medium';
const Api = Object.assign({
  ...loginApi,
  ...videoApi,
  ...homeApi,
  ...classApi,
  ...manageUPAPI,
  ...projectApi,
  ...adminApi,
  ...menuApi,
  ...terminalApi,
  ...rolesApi,
  ...baseConfig,
  ...rolesApi,
  ...mediumApi,
  ...acgApi
});
export default Api;
