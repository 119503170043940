<template>
  <div>

    <el-submenu :index="item.path"   v-if="item.children && item.children.length">
      <template slot="title">
        <i :class="item.icon + ' icon'" style="color:white"></i>
        <span>{{item.title}}</span>
      </template>
      <el-menu-item-group>
        <siderbar-item v-for="(items,i) in item.children" :key="i" :item="items"></siderbar-item>
      </el-menu-item-group>
    </el-submenu>
    <el-menu-item
        :index="item.path"
        @click="addRouterTag(item.path,item.title)"
        v-else
    >
      <i :class="item.icon + ' icon'" style="color:white "></i>
      <span slot="title">{{item.title}}</span>
    </el-menu-item>
  </div>
</template>
<script>
export default {
  name: "siderbarItem",
  props: ["item"],
  mounted() {
    // 初始化路由tags显示1
    let thisPage = ""
    this.$store.state.routerList.forEach(item=>{
      item.children.forEach(v=>{
        if(v.path === this.$route.path){
          thisPage = v.title
        }
      })
    })
    // const thisPage = this.$store.state.routerList.find(v=>{v.children.find(n=>{return n.path === this.$route.path})})
    this.addRouterTag(this.$route.path, thisPage);
  },
  methods: {
    // 提交当前选中路由名称和路径到vuex
    addRouterTag(router, routerName) {
      if (router === "/welcome") {
        return;
      }
      this.$store.dispatch("setRouterActive", router);
      let routers = this.$store.state.routerTags;
      let flag = true;
      let index;
      if (routers.length === 0) {
        this.$store.dispatch("setRouterTag", { router, routerName });
        return;
      }
      routers.forEach((item, i) => {
        if (item.router === router) {
          flag = false;
          index = i;
        }
      });
      if (flag) {
        this.$store.dispatch("setRouterTag", { router, routerName });
      } else {
        this.$store.dispatch("setRouterIndex", index + 1);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
  .el-menu-item>.icon{
    color: #fff !important;
  }
  .is-active>.icon{
    color: #000 !important;
  }
</style>
