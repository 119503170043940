import ajax from '../utils/request'
const manageUPAPI = {
    getUpListData(params) {
        return ajax({
            url: '/api/vlogger/list',
            type: 'get',
            params
        })
    },
    createdUpAcc(params) {
        return ajax({
            url: '/api/vlogger/create',
            type: 'post',
            params
        })
    },
    getMenuList() {
        return ajax({
            url: '/api/auth/admin/menu/ownMenu',
            type: 'get'
        })
    },
}
export default manageUPAPI
