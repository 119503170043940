import ajax from '../utils/request'
const terminalApi = {
  /********************************终端相关接口*********************************** */
  getTerminalList(params) {
    return ajax({
      url: '/api/customer/terminal/list',
      type: 'get',
      params
    })
  },

  createTerminalList(params) {
    return ajax({
      url: '/api/customer/terminal/create',
      type: 'post',
      params
    })
  },

  updateTerminalList(params) {
    return ajax({
      url: '/api/customer/terminal/update',
      type: 'post',
      params
    })
  },
}
export default terminalApi

