<template>
  <div class="videoList-box" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <!--操作栏-->
    <div class="action-bar">
      审核状态:
      <el-select clearable v-model="params.status" placeholder="审核状态" size="small" @change="getVideoList('status')">
        <el-option v-for="item in status" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-input v-model="params.title" placeholder="请输入帖子标题" clearable @keyup.enter.native="getVideoList('status')" size="small" class="param-input"></el-input>
      <el-date-picker v-model="time" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small" @change="changeTime"></el-date-picker>
      <el-button type="primary" size="small" @click="getVideoList('status')">搜索</el-button>
    </div>
    <!--数据表格-->
    <el-table :data="tableData" style="width: 100%" border ref="multipleTable" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" height="580" :key="updataSta">
      <el-table-column prop="id" label="帖子Id" align="center" width="140" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span>{{ scope.row.id }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="title" label="视频标题" align="center" :show-overflow-tooltip="true" width="350">
        <template slot-scope="scope">
          <span>{{ scope.row.title || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="标签" align="center" width="120">
        <template slot-scope="scope">
          <el-tag style="margin: 5px 0" type="success">
            {{scope.row.tags_text}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="审核结果" align="center" width="120">
        <template slot-scope="scope">
          <el-tag style="margin: 5px 0" type="success">
            <span v-if="scope.row.status === 10">已通过</span>
            <span v-else-if="scope.row.status === 7">未通过</span>
            <span v-else>处理中</span>
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="封面图" align="center" width="120">
        <template slot-scope="scope">
          <img-load :key="scope.row.cover_image" :src="handleImageUrl(scope.row.cover_image)"></img-load>
        </template>
      </el-table-column>
      <el-table-column label="帖子预览" align="center" width="80">
        <template slot-scope="scope">
          <i class="el-icon-video-play views" title="点击预览" @click="checkMedia(scope.row.src_480, 'video')"></i>
        </template>
      </el-table-column>
      <el-table-column prop="length" label="总时长" align="center" width="130">
        <template slot-scope="scope">{{ scope.row.length | changeSecond }}</template>
      </el-table-column>
      <el-table-column prop="size" label="帖子大小(M)" align="center" width="100">
        <template slot-scope="scope">
          <span>{{ (scope.row.file_size_480 / 1024 / 1024) | transformFloat }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="update_time" label="更新时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.update_time | changeDate }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="上传时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.create_time | changeDate }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--封面图、视频弹窗组件-->
    <media-popup ref="mediaPopup"></media-popup>
    <!-- 分页组件 -->
    <div class="bottom">
      <div class="page">
        <el-pagination :page-sizes="[10, 25, 50, 100]" :current-page="params.pageNumber" :page-size="params.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total" background v-if="total > 10" @size-change="changeSize" @current-change="changeCurrent"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import MediaPopup from "@/components/media-popup";
import { changeTime } from "@/utils/global";
import API from "@/api";
export default {
  data() {
    return {
      // 状态
      status: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "通过",
          value: "10",
        },
        {
          label: "未通过",
          value: "7",
        },
        {
          label: "处理中",
          value: "0,6",
        },
      ],
      loading: false,
      // 列表数据
      tableData: [],
      total: "",
      time: "",
      // 请求列表查询
      params: {
        pageNumber: 1,
        pageSize: 10,
        start: "",
        end: "",
        start1: 0,
        end1: 0,
        title: "",
        status: "",
        id: "",
      },
      videoIds: [],
      dialogVisible: false,
      isDisabled: false,
      updataSta: false,
    };
  },
  components: {
    MediaPopup,
  },
  created() {
    this.getVideoList();
  },
  methods: {
    handleImageUrl(src) {
      if (src[0] === "/") {
          src = src.substr(1, src.length)
      }
      return src
    },
    // 获取视频列表
    getVideoList(type) {
      var params = {
        "page": this.params.pageNumber,
        "size": this.params.pageSize,
      }
      if (type === "status") {
        this.params.page = 1;
      }
      if (this.params.status !== "") {
        params["filter[status]"] = this.params.status
      }
      if (this.params.title !== "") {
        params["filter[title]"] = this.params.title
      }
      if (this.params.start1 > 0) {
        params["filter[create_time]"] = String(this.params.start1) + "," + String(this.params.end1)
      }
      this.loading = true;
      API.getVideoList(params)
        .then((res) => {
          if (res.code === 0 && res.data.data) {
            this.tableData = res.data.data;
            this.total = res.data.pagination.count;
            if (this.isDisabled) {
              this.restoreCheckedState();
            }
          } else if(res.code === 401) {
            this.$message.error("登陆信息过期,请重新登陆!");
            return
          } else {
            this.tableData = [];
            this.total = 0;
          }
          this.loading = false;
        })
        .catch(() => {
          this.tableData = [];
          this.loading = false;
        });
    },
    // 分页条数改变回调
    changeSize(size) {
      this.params.pageSize = size;
      this.getVideoList();
    },
    // 分页页码改变回调
    changeCurrent(number) {
      this.params.pageNumber = number;
      this.getVideoList();
    },
    // 时间触发回调
    changeTime(time) {
      if (time) {
        this.params.start = changeTime(time[0]) || "";
        this.params.end = changeTime(time[1]) || "";
        this.params.start1 = time[0].getTime()/1000;
        this.params.end1 = time[1].getTime()/1000;
      } else {
        this.params.start = "";
        this.params.end = "";
        this.params.start1 = 0;
        this.params.end1 = 0;
      }
      this.getVideoList("status");
    },
    // 编辑回调监听
    handlerVideoList() {
      this.getVideoList();
    },
    handlerPreviewList() {
      this.getVideoList();
    },
    // 查看媒体
    checkMedia(url, type) {
      this.$refs.mediaPopup.open(url, type);
    },
    // 编辑视频
    edit(row) {
      this.$refs.editVideo.open(row);
    },
    // 翻页时的勾选回显
    restoreCheckedState() {
      /**
       * ①获取到当前数据表格中已经被选中的数据
       */
      const indexs = this.tableData
        .map((item) => {
          let flag = false;
          this.videoIds.forEach((value) => {
            value.id === item.id ? (flag = true) : "";
          });
          if (flag) {
            return item;
          }
        })
        .filter((item) => item);
      /**
       * ②回显当前数据表格中已经被勾选过的数据
       */
      this.$nextTick(() => {
        indexs.forEach((rows) => {
          this.$refs.multipleTable.toggleRowSelection(rows);
        });
      });
    },
    giveUp() {
      this.batchContent = "";
      this.isDisabled = false;
      this.videoIds = [];
      this.$refs.multipleTable.clearSelection();
    },
    // 关闭窗口
    close() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped lang="scss">
.picturePreview {
  max-width: 300px;
  display: flex;
}
.videoList-box {
  min-height: 100vh;
  overflow-y: auto;
}
.bottom {
  margin-top: 20px;
  .btn {
    display: inline-block;
    margin-left: 20px;
  }
  .page {
    display: inline-block;
    float: right;
  }
}
.el-pagination {
  margin-top: 0 !important;
}
.dialog-footer {
  left: 0;
}
.tagBox {
  display: inline-block;
  margin-right: 5px;
}
</style>
