import ajax from '../utils/request'
const projectApi = {
    /********************************标签相关接口*********************************** */
    getProductList(params) {
        return ajax({
            url: '/api/customer/product/list',
            type: 'get',
            params
        })
    },
    createProduct(params){
        return ajax({
            url:'/api/customer/product/create',
            type:'post',
            params
        })
    },
    updateProduct(params){
        return ajax({
            url:'/api/customer/product/update',
            type:'post',
            params
        })
    },
    // 获取终端列表
    getTerminalList(params){
        return ajax({
            url:'/api/customer/terminal/list',
            type:'get',
            params
        })
    },

    // UP主产品关联管理
    getUpProjectList(params){
        return ajax({
            url:'/api/vlogger/product/list',
            type:'get',
            params
        })
    },
    getImagesUrl(){
        return ajax({
            url:`/api/general/system/settings`,
            type:'get'

        })
    },
    updateUser(params){
        return ajax({
            url:`/api/vlogger/update`,
            type:'post',
            params
        })
    },
    updateUserV2(params){
        return ajax({
            url:`/api/vlogger/product/update`,
            type:'post',
            params
        })
    },
    getVideoList(params,name){
        return ajax({
            url:`/api/video${name}/list`,
            type:'get',
            params
        })
    },
    updateVideoData(params){
        return ajax({
            url:`/api/video/short/update`,
            type:'post',
            params
        })
    },
    fileUpload(params){
        console.log(params)
        return ajax({
            url: '/api/general/system/uploadImage',
            type: 'post',
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            params
        })
    },
    updateVideoDatav(params){
        return ajax({
            url:`/api/video/update`,
            type:'post',
            params
        })
    },
    delVideoFile(url,params){
        return ajax({
            url: url,
            type:'post',
            params
        })
    },
    createdUpAss(params){
        return ajax({
            url:`/api/vlogger/product/create`,
            type:'post',
            params
        })
    },
    deleteUpAss(params){
        return ajax({
            url:`/api/vlogger/product/destroy`,
            type:'post',
            params
        })
    },
    getRolesList(params){
        return ajax({
            url:`/api/auth/admin/role/list`,
            type:'get',
            params
        })
    },
    getVideocheck(params,name){
        return ajax({
            url:`/api/video${name}/checkRS`,
            type:'post',
            params
        })
    },
    onlineVideo(params){
        return ajax({
            url:`/api/video/batchOnline`,
            type:'post',
            params
        })
    },
    offlineVideo(params){
        return ajax({
            url:`/api/video/batchOffline`,
            type:'post',
            params
        })
    },
    onlineVideoshort(params){
        return ajax({
            url:`/api/video/short/batchOnline`,
            type:'post',
            params
        })
    },
    offlineVideoshort(params){
        return ajax({
            url:`/api/video/short/batchOffline`,
            type:'post',
            params
        })
    },
    // 一键删除问题视频
    destoryAllVideo(params){
        return ajax({
            url:`/api/video/destroyAll`,
            type:'post',
            params
        })
    },
    // 一键删除问题视频
    destoryAllShortVideo(params){
        return ajax({
            url:`/api/video/short/destroyAll`,
            type:'post',
            params
        })
    },
}
export default projectApi
