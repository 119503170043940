import ajax from '../utils/request'
const mediumApi = {
    /********************************视频相关接口*********************************** */
    creatMediumList(params) {
      return ajax({
          url: '/api/video/sets/create',
          type: 'post',
          params
      })
  },
  updateMediumList(params) {
    return ajax({
        url: '/api/video/sets/update',
        type: 'post',
        params
    })
},
}
export default mediumApi

