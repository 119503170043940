import Main from '../views/main'
 

const develop = [{
    icon : "el-icon-video-camera-solid",
    meta :  { keepAlive : true},
    component: Main,
    path :  "/manage",
    title  : "常规管理",
    children : [{
        children : [],
        icon : "el-icon-video-camera-solid",
        meta :  { keepAlive : true},
        path :  "/manage/system",
        title  : "系统配置",
        component:  () => import('@/views/manage/system'),

    },{
        icon : "el-icon-video-camera-solid",
        meta :  { keepAlive : true},
        path :  "/manage/information",
        title  : "个人资料",
        component:  () => import('../../src/views/manage/information'),
    },
    //     {
    //     icon : "el-icon-video-camera-solid",
    //     meta :  { keepAlive : true},
    //     path :  "/manage/video-file",
    //     title  : "媒体文件管理",
    //     component:  () => import('../../src/views/manage/videoFile'),
    // }
    ]},
    {
        icon : "el-icon-video-camera-solid",
        meta :  { keepAlive : true},
        path :  "/power",
        title  : "权限管理",
        component: Main,
        children : [{
            children : [],
            icon : "el-icon-video-camera-solid",
            meta :  { keepAlive : true},
            path :  "/power/administrators",
            title  : "管理员管理",
            component:  () => import('../../src/views/power/administrators'),
        },{
            children : [],
            icon : "el-icon-video-camera-solid",
            meta :  { keepAlive : true},
            path :  "/power/roles",
            title  : "角色组",
            component:  () => import('../../src/views/power/roles'),
        },{
            children : [],
            icon : "el-icon-video-camera-solid",
            meta :  { keepAlive : true},
            path :  "/power/menu",
            title  : "菜单规则",
            component:  () => import('../../src/views/power/menu'),
        }],
    },
    {
        icon : "el-icon-video-camera-solid",
        meta :  { keepAlive : true},
        path :  "/access",
        title  : "接入管理",
        component: Main,
        children : [{
            children : [],
            icon : "el-icon-video-camera-solid",
            meta :  { keepAlive : true},
            path :  "/access/project",
            title  : "产品接入管理",
            component:  () => import('../../src/views/access/project.vue'),
        },{
            children : [],
            icon : "el-icon-video-camera-solid",
            meta :  { keepAlive : true},
            path :  "/access/terminal",
            title  : "终端管理",
            component:  () => import('../../src/views/access/terminal'),
        }],
    },
    {
        icon : "el-icon-video-camera-solid",
        meta :  { keepAlive : true},
        path :  "/tabs",
        title  : "标签管理",
        component: Main,
        children : [{
            children : [],
            icon : "el-icon-video-camera-solid",
            meta :  { keepAlive : true},
            path :  "/tabs/classification",
            title  : "标签分类管理",
            component:  () => import('../../src/views/tabs/classification'),
        },{
            children : [],
            icon : "el-icon-video-camera-solid",
            meta :  { keepAlive : true},
            path :  "/tabs/index",
            title  : "标签管理",
            component:  () => import('../../src/views/tabs/index'),
        }],
    },
    {
        icon : "el-icon-video-camera-solid",
        meta :  { keepAlive : true},
        path :  "/medium",
        title  : "媒体资源管理",
        component: Main,
        children : [{
            children : [],
            icon : "el-icon-video-camera-solid",
            meta :  { keepAlive : true},
            path :  "/medium/video",
            title  : "视频管理",
            component:  () => import('../../src/views/medium/video'),
        }
        ,{
            children : [],
            icon : "el-icon-video-camera-solid",
            meta :  { keepAlive : true},
            path :  "/medium/continuous",
            title  : "视频连载管理",
            component:  () => import('../../src/views/medium/continuous'),
        }
        ,{
            children : [],
            icon : "el-icon-video-camera-solid",
            meta :  { keepAlive : true},
            path :  "/medium/short-video",
            title  : "短视频管理",
            component:  () => import('../../src/views/medium/shortVideo'),
        },
        //     {
        //     children : [],
        //     icon : "el-icon-video-camera-solid",
        //     meta :  { keepAlive : true},
        //     path :  "/medium/take-off",
        //     title  : "起飞视频管理",
        //     component:  () => import('../../src/views/medium/takeOff'),
        // },
        //     {
        //     children : [],
        //     icon : "el-icon-video-camera-solid",
        //     meta :  { keepAlive : true},
        //     path :  "/medium/resource-error",
        //     title  : "资源错误上报",
        //     component:  () => import('../../src/views/medium/resourceError'),
        // },
        //     {
        //     children : [],
        //     icon : "el-icon-video-camera-solid",
        //     meta :  { keepAlive : true},
        //     path :  "/medium/video-examine",
        //     title  : "预热记录管理"
        // }
        ],
    },
    {
        icon : "el-icon-camera",
        meta :  { keepAlive : true},
        path :  "/acg",
        title  : "acg管理",
        component: Main,
        children : [{
            icon : "el-icon-camera-solid",
            meta :  { keepAlive : true},
            path :  "/acg/index",
            title  : "acg列表管理",
            component:  () => import('../../src/views/agc/index')
        },
        {
            icon : "el-icon-camera-solid",
            meta :  { keepAlive : true},
            path :  "/acg/list",
            title  : "acg子集待审核列表",
            component:  () => import('../../src/views/agc/list')
        }],
    },
    {
        icon : "el-icon-video-camera-solid",
        meta :  { keepAlive : true},
        path :  "/videoProduction",
        title  : "UP主管理",
        component: Main,
        children : [{
            children : [],
            icon : "el-icon-video-camera-solid",
            meta :  { keepAlive : true},
            path :  "/videoProduction/account",
            title  : "UP主账号管理",
            component:  () => import('../../src/views/videoProduction/manage'),
        },{
            children : [],
            icon : "el-icon-video-camera-solid",
            meta :  { keepAlive : true},
            path :  "/videoProduction/association",
            title  : "UP主平台关联管理",
            component:  () => import('../../src/views/videoProduction/association'),
        }],
    }
]

export default develop
