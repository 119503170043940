<template>
  <div :class="isBetWeen ? 'btnboxs isBetWeen' : 'btnboxs'">
    <div class="btnboxs_left">
      <el-button @click="refeshData" :size="size?'':'mini'">刷新</el-button>
      <div class="left_btn">
        <slot name="left_btn"></slot>
      </div>
    </div>
    <div class="search-btns" ref="searchBtns">
        <slot name="search-btns"></slot>
    </div>
  </div>
</template>

<script>
export default {
    name:"refesh",
    props:['isBetWeen','size'],
    methods:{
        refeshData(){
            this.$emit('refesh')
        }
    }
}
</script>

<style lang="scss" scoped>
.btnboxs_left{
  display: flex;
}
.left_btn{
  margin: 0 0 0 10px;
}
.btnboxs{
  display: flex;
  margin-bottom: 10px;
}
.search-btns{
  margin-left: 10px;
}
.isBetWeen{
  display: flex;
  justify-content: space-between;
}
</style>