/*
 * @Author: 王枫叶
 * @Date: 2020-10-31 17:53:35
 * @LastEditors: 王枫叶
 * @LastEditTime: 2020-12-17 21:24:56
 */
// 视频管理
import VideoListRouter from "./videoList";
import develop from "./base";
// 不需要权限控制的路由
const authRouter = [
  VideoListRouter,
     ...develop
];
// console.log(authRouter)
export default authRouter;
