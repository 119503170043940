<template>
  <el-aside width="250px">
    <el-menu
      :default-active="activeRouter"
      router
      class="el-menu-vertical-demo"
      background-color="#161823"
      :unique-opened="true"
      text-color="#A7A8AC"
      active-text-color="#FFF"
    >
      <siderbar-item
        v-for="(item, i) in menuList"
        :key="i"
        :item="item"
      ></siderbar-item>
    </el-menu>
  </el-aside>
</template>

<script>
import siderbarItem from "./siderbarItem";
export default {
  data() {
    return {
      activeRouter: this.$route.path,
      menu: [],
    };
  },
  components: {
    siderbarItem,
  },
  computed: {
    // 实时计算当前选中路由名称
    stateRouter() {
      return this.$store.state.activeRouter;
    },
    // 实时获取菜单
    menuList() {
      return this.$store.state.routerList;
    },
  },

  watch: {
    stateRouter(router) {
      this.activeRouter = router;
    },
  },
};
</script>
<style lang="scss">
.el-aside {
  background: rgb(22, 24, 35);
  padding-bottom: 20px;
  .el-menu {
    border-right: none;
    min-height: 100%;
  }
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 10px;
    // background-color: #721729;
  }
  &::-webkit-scrollbar-track {
    background-color: #161823;
  }
  .el-menu-item {
    // background: transparent !important;
    font-size: 13px;
    &:hover {
      background: #fff !important;
      color: #000 !important;
      i {
        color: #000 !important;
      }
    }
  }
  .el-submenu {
    font-size: 13px;
  }
  .el-menu-item-group__title {
    display: none;
  }
  .el-submenu__title {
    &:hover {
      background: #fff !important;
      color: #000 !important;
      i {
        color: #000 !important;
      }
    }
  }
  .el-icon-arrow-down {
    color: white;
  }
  //.el-menu-vertical-demo {
  //  background: rgba(0, 0, 0, 0.5) !important;
  //}

  .is-active {
    color: #000 !important;
    background-color: #fff !important;
  }
  .is-active i {
    color: #000 !important;
  }

  .el-submenu__title i {
    color: white !important;
  }
  .icon {
    margin-top: -3px;
  }
}
</style>
