<template>
  <div class="login-main">
    <div class="black-bg-box">
      <div class="title">后台管理系统</div>
      <div class="login-form-box">
        <p>登录</p>
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="80px"
          class="login-from"
          v-show="!googleShow"
        >
          <el-form-item label="账号:" prop="admin_name">
            <el-input v-model="form.admin_name"></el-input>
          </el-form-item>
          <el-form-item label="密 码:" class="password" prop="password">
            <el-input
              v-model="form.password"
              show-password
              @keyup.native.enter="submitForm"
            ></el-input>
          </el-form-item>
          <el-form-item style="margin-top: 15px">
            <el-checkbox v-model="checked">记住密码？</el-checkbox>
          </el-form-item>
          <el-button
            :loading="loading"
            type="primary"
            class="login-in"
            @click="submitForm"
            >下一步</el-button
          >
        </el-form>
        <el-form
            v-show="googleShow"
            label-width="120px"
            class="login-from"
        >
          <el-image :src="'data:image/png;base64,'+googleImage" v-if="googleImage"></el-image>
          <el-form-item label="Google身份验证:">
            <el-input v-model="googleCode"></el-input>
          </el-form-item>
          <el-button
              :loading="loading"
              type="primary"
              class="login-in"
              @click="submitForm"
          >登录</el-button
          >
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import API from "@/api";
import local from "@/utils/localStroage.js";
export default {
  name: "login",
  data() {
    return {
      form: {
        admin_name: "",
        password: ""
      },
      googleCode:"",
      googleImage: "",
      googleShow:false,
      QRcode: "",
      rules: {
        admin_name: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }]
      },
      checked: false,
      loading: false
    };
  },
  created() {
    // 从localStroage读取用户登录信息，存在则自动填充密码
    let loginInfo = local.get("loginInfo");
    if (loginInfo) {
      this.checked = true;
      this.form = loginInfo;
    }
    // 免密码登录
    // this.saveUserInfo( {
    //   "admin_name": 123,
    //   "otp": 123,
    //   "remember": 123
    // });
    // this.$store.dispatch("setToken",'daadbf44-b130-45e3-a91d-69b0a5892ef2');
    // this.$router.push({
    //   path: "/welcome"
    // });
  },

  methods: {
    // 表单验证提交
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.flag = true;
          this.firstLogin()
        } else {
          return false;
        }
      });

    },
    // 登录保存登录状态到本地存储器
    firstLogin() {
      let params = Object.assign({}, this.form);
      //是否记住密码
      params.remember = 0;
      if (this.checked) {
        params.remember = 1;
      }
      if(this.googleShow) {
        const paramsData = {
          "admin_name": this.form.admin_name,
          "otp": this.googleCode,
          "remember": params.remember
        }
        API.getUserInfo(paramsData).then(resInfo=>{
          if(!resInfo.code){
            this.saveUserInfo(resInfo.data);
          }else {
            this.$message.error(resInfo.msg);
          }

        })
        return false
      }
      API.login(params)
        .then(res => {
          if (res.code === 0) {
            this.googleShow = true;
            this.loading = false
            if(res.data.need_bind){
              this.googleImage = res.data.qr_code_png
            }

          } else{
            this.$message.error("账号或密码错误，请重新登陆");
            this.loading = false
          }
        })
        .catch(err => {
          this.loading = false
          this.$message.error(err);
        });
    },
    // 记住密码
    saveUserInfo(userInfo) {
      if (this.checked) {
        local.set("loginInfo", this.form);
      } else {
        local.remove("loginInfo");
      }
      // 保存用户信息
      local.set("userInfo", userInfo);
      // 获取菜单数据
      API.getMenuListown().then(res=>{
        let menuData = res.data.data
        localStorage.setItem('UserMenu', JSON.stringify(menuData))
        if(!menuData.length){
          this.$message.error('请联系管理员为您配置权限')
          local.remove("userInfo");
          local.remove("role");
          local.remove("UserMenu");
          local.remove("btnPower");
          this.$store.dispatch("removeAllTag");
          this.loading = false
          return false
        }
        this.$router.addRoutes(menuData);
        // 刷新动态权限路由,权限路由从localstorage中获取
        this.$store.dispatch("setToken",userInfo.token);
        API.getImagesUrl().then(res=>{
          local.set("imageUrl",JSON.stringify( res.data.ImgDomain));
          this.$store.dispatch("refreshRouter");
          location.href = '/'
          this.loading = false
          // 登录成功过后重定向到欢迎页面
        })
      })
    }
  }
};
</script>
<style lang='scss' scoped>
.login-main {
  width: 100%;
  height: 100%;
  background: #0e0d1f;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
  .black-bg-box {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .title {
    color: white;
    font-size: 52px;
    font-weight: bold;
    text-align: center;
    margin-top: 150px;
    letter-spacing: 5px;
  }
  .login-form-box {
    width: 400px;
    height: auto;
    background: rgba(255, 255, 255, 1);
    margin: 0 auto;
    border-radius: 5px;
    margin-top: 80px;
    padding: 20px;
    padding-left: 0;
  }
  p {
    color: #333;
    text-align: center;
    line-height: 40px;
  }
  .login-from {
    // text-align: center;
    margin-top: 20px;
    //.el-input {
    //  width: 300px;
    //}
    .password {
      margin-bottom: 0px;
    }
    .el-checkbox {
      font-weight: normal;
    }
    .login-in {
      width: 75%;
      margin-left: 15%;
    }
  }
}
</style>

