<template>
<div>
  <el-form ref="form" :model="form" label-width="80px">
    <el-form-item label="语言">
      <AddFormItem :baseData.sync="form.baseData"></AddFormItem>
    </el-form-item>
  </el-form>

</div>
</template>

<script>
export default {
  name: "testAddFormItem",
  data(){
    return{
      form:{

        content: "string",
        create_time: 0,
        id: 0,
        is_required: true,
        name: "string",
        sort_order: 0,
        tip: "string",
        title: "string",
        type: "string",
        update_time: 0,
        value: "string"
      },

    }
  },
  components:{
  }
}
</script>

<style scoped>

</style>
