<template>
  <el-header>
    <div class="logo-text">
      <img :src="logoImg" class="logoImg" />
      <span>媒资库后台</span>
    </div>
    <div class="header-hint">
<!--      请注意: 上传视频过程中电脑不要锁屏!-->
    </div>
    <div class="header-btn">
      <el-button
        v-show="isShow"
        class="beilv"
        id="beilv"
        style="width:30px"
        type="text"
        @click="getValue()"
      >{{expansionFactor}}</el-button>
      <span class="exit" @click="logout">退出</span>
      <span>
        您好！
        <i class="user-name">{{ userInfo ? userInfo.user_name : '未知'}}</i>,欢迎登录
      </span>
    </div>
  </el-header>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import local from "@/utils/localStroage.js";
export default {
  name: "dg-header",
  data() {
    return {
      isShow: true,
      userName: "",
      logoImg: require("../../../assets/images/icon.png"),
    };
  },
  computed: {
    ...mapGetters(["expansionFactor"]),
    userInfo: () => {
      return local.get("userInfo");
    },
  },
  created() {},
  methods: {
    ...mapActions(["setBeilv"]),
    getValue() {
      this.$prompt("请输倍率", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^\d+(\.\d+)?$/,
        inputErrorMessage: "请输入倍率不为负的数字",
      })
        .then(({ value }) => {
          this.setBeilv(value);
          this.isShow = false;
          this.$message({
            type: "success",
            message: "你的输入的倍率是: " + value,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    // 点击退出
    logout() {
      this.$confirm("您确定要退出当前账户？", "退出提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.exit();
        })
        .catch(() => {});
    },
    // 登出
    exit() {
      local.remove("userInfo");
      local.remove("role");
      local.remove("UserMenu");
      local.remove("btnPower");
      this.$store.dispatch("removeAllTag");
      this.$router.push({
        path: "/login",
      });
    },
  },
};
</script>
<style lang="scss">
.el-header {
  text-align: center;
  width: 100%;
  height: 80px !important;
  line-height: 80px;
  display: flex;
  background-color: #161823;

  .logo-text {
    font-size: 22px;
    color: #fff;
    flex: 1;
    text-align: left;
    display: inline;
    .logoImg {
      width: 68px;
      height: 58px;
      vertical-align: middle;
    }
    span {
      vertical-align: middle;
      margin-left: 15px;
    }
  }
  .header-btn {
    flex: 1;
    text-align: right;
    font-size: 14px;
    color: #fff;
    .beilv {
      position: fixed;
      top: 23px;
      left: 371px;
      position: fixed;
    }
    input {
      border: none;
      outline: none;
    }
  }
  .header-hint {
    flex: 1;
    text-align: right;
    font-size: 35px;
    color: red;
  }
  .user-name {
    color: white;
  }
  .exit {
    margin: 0 20px;
    cursor: pointer;
    &:hover {
      color: white;
    }
  }
}
</style>''
