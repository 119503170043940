import ajax from "../utils/request";
const classApi = {
  /********************************标签相关接口*********************************** */
  getCateList(params) {
    return ajax({
      url: "/api/tag/cate/list",
      type: "get",
      params,
    });
  },
  createCate(params) {
    return ajax({
      url: "/api/tag/cate/create",
      type: "post",
      params,
    });
  },
  destroyCate(params) {
    return ajax({
      url: "/api/tag/cate/destroy",
      type: "post",
      params,
    });
  },
  updateCate(params) {
    return ajax({
      url: "/api/tag/cate/update",
      type: "post",
      params,
    });
  },
  createTag(params) {
    return ajax({
      url: "/api/tag/create",
      type: "post",
      params,
    });
  },
  destroyTag(params) {
    return ajax({
      url: "/api/tag/destroy",
      type: "post",
      params,
    });
  },
  getTagList(params) {
    return ajax({
      url: "/api/tag/list",
      type: "get",
      params,
    });
  },
  getTagTree(params) {
    return ajax({
      url: "/api/tag/tree",
      type: "get",
      params,
    });
  },
  tagUpdate(params) {
    return ajax({
      url: "/api/tag/update",
      type: "post",
      params,
    });
  },
  prodyctTagList(params) {
    return ajax({
      url: "/api/tag/product/refresh",
      type: "post",
      params,
    });
  },
  getEchoTagTree(params) {
    return ajax({
      url: "/api/tag/product/all",
      type: "get",
      params,
    });
  },
  batchUpdateTag(params) {
    return ajax({
      url: "/api/video/batchUpdateTag",
      type: "post",
      params,
    });
  },
  batchUpdateShortTag(params) {
    return ajax({
      url: "/api/video/short/batchUpdateTag",
      type: "post",
      params,
    });
  },
};
export default classApi;
