import Vue from "vue";
import Vuex from "vuex";
import state from "./state";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import modules from "./modules";
// import createPersistedState from "vuex-persistedstate";

//调用方法，使用插件
Vue.use(Vuex);

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules,
  // plugins: [createPersistedState()],
});
