// import authRouter from "../router/permissionRouter.js";
import list from "@/router/permissionRouter";
import { recursionRouter } from "@/utils/global";
import { btnsMap, getPathList } from "./request";
// action类似与mutation，action提交的是mutation而不是直接变更状态，action可以包含任意一步操作
const actions = {
  setRouterTag: ({ commit }, tag) => commit("SET_TAGS", tag), // 设置tag
  setRouterIndex: ({ commit }, index) => commit("SET_INDEX", index), // 设置当前选中的路由
  setRouterActive: ({ commit }, router) => commit("SET_ROUTER_ACTIVE", router), // 设置导航栏动态路由选中
  removeRouterTag: ({ commit }, index) => commit("REMOVE_TAGS", index), // 删除tag并切换路由
  removeAllTag: ({ commit }) => commit("REMOVE_ALL_TAGS"), // 删除全部tags
  setRouter: ({ commit }, list) => commit("SET_ROUTER", list), // 存储动态路由
  setBeilv: ({ commit }, beilv) => commit("SET_BEILV", beilv), // 存储模拟倍率
  setToken: ({ commit }, token) => {
    commit("SET_TOKEN", token);
  }, // 存储token，主要用来刷新token后，被上传图片监听到，可以修改页面
  removeToken: ({ commit }) => commit("REMOVE_TOKEN"), //删除token

  /**
   * @description 刷新路由:从logcastorage中获取权限路由,对比本地的总路由，获取新的路由，保存到routerList中。
   */
  refreshRouter: ({ dispatch, commit }) => {
    let role = JSON.parse(localStorage.getItem("UserMenu")),
      //重新创建新的本地权限路由，避免修改原始权限数据
      newList = list.map((item) => Object.assign({}, item));
    // newList = _.cloneDeep(list),
    //通过该方法将用户权限路由和所有动态路由进行对比生成新的路由
    //newRouter;
    if (!role || role.length === 0) {
      commit("SET_ROUTER", []);
    }
    let newRouter = recursionRouter(role, newList);
    // 触发vuex函数对新路由进行存储（主要针对页面刷新后vux保存的路由无法存留，注:不能将获取的用户路由存存在localstroge里面，因为路由的components在本地存储当中会被转化),注：localstorage里面的数据不能被实时监听
    // dispatch("setRouter", newList);
    getPathList(role);
    localStorage.setItem("btnPower", JSON.stringify(btnsMap));
    dispatch("setRouter", newRouter);
  },

  // 设置登陆弹窗的弹出次数
  setConfirmFlag: ({ commit }) => {
    commit("SET_CONFIRM_FLAG");
  },
  actJson: ({ commit }, actJson) => commit("ACT_JSON", actJson),
};

export default actions;
