/*
 * @Author: 王枫叶
 * @Date: 2020-09-28 16:52:13
 * @LastEditors: 王枫叶
 * @LastEditTime: 2020-12-19 14:39:39
 */
const baseConfig = {
  baseServerUrl: "https://midht.cestalt.com",
  decyptImgUrl: "这里是图片cdn域名", //解密图片预览
}
if (
  process.env.NODE_ENV === "production" &&
  process.env.VUE_APP_TITLE === "test"
) {
  baseConfig.baseServerUrl = "https://midht.cestalt.com"
} else if (process.env.NODE_ENV === "development") {
  // 测试服
  baseConfig.baseServerUrl =  "https://midht.cestalt.com"
} else {
  baseConfig.baseServerUrl = "https://media-web-api.zvcpw.com"
}
export default baseConfig
