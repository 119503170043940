<template>
  <el-main>
    <div class="main">
      <div class="route-tags">
        <div class="btn-hover out-box color-11">
          <div class="popup">导航标签:</div>
        </div>
        <div
          class="btn-hover out-box"
          :class="['color-' + (i + 1), { active: i === index }]"
          v-for="(item, i) in tags"
          :key="i"
          @click="cutRouter(i, item.router)"
        >
          <div class="popup">
            {{ item.routerName }}
            <i class="el-icon-close" @click.stop="remove(i)"></i>
          </div>
        </div>
        <!--假加载条美化效果-->
        <div class="outside-bar">
          <div class="inside-bar" :class="loading ? 'loading' : ''"></div>
        </div>
      </div>
      <keep-alive>
        <router-view
          v-if="$route.meta.keepAlive"
          style="min-height: 100%"
        ></router-view>
      </keep-alive>
      <router-view
        v-if="!$route.meta.keepAlive"
        style="min-height: 100%"
      ></router-view>
    </div>
  </el-main>
</template>
<script>
import { setTimeout } from "timers";
export default {
  data() {
    return {
      index: 0,
      loading: false
    };
  },
  computed: {
    tags() {
      return this.$store.state.routerTags;
    },
    routerIndex() {
      return this.$store.state.routerIndex;
    }
  },
  methods: {
    // 切换tags路由
    cutRouter(i, router) {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 500);

      this.index = i;
      if (!router) {
        return;
      }
      this.$store.dispatch("setRouterActive", router);
      this.$router.push({
        path: router
      });
    },
    // 删除tsgs 路由
    remove(i) {
      this.$store.dispatch("removeRouterTag", i);
      let routerTag = this.$store.state.routerTags;
      this.cutRouter(routerTag.length, routerTag[routerTag.length - 1].router);
    }
  },
  watch: {
    routerIndex(i) {
      i--;
      this.cutRouter(i, "");
    }
  }
};
</script>

<style lang="scss">
.el-main {
  height: 100%;
  overflow: hidden !important;
  padding: 0 !important;
  position: relative;
  .main {
    width: 100%;
    height: 100%;
    background: white;
    overflow-y: auto !important;
    padding: 60px 20px 20px 20px !important;
    position: relative;
  }
  .route-tags {
    width: calc(100% - 40px);
    height: 40px;
    background: white;
    margin-bottom: 15px;
    display: inline-block;
    box-shadow: 0px 15px 10px -10px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 5px 5px;
    position: fixed;
    top: 80px;
    left: 270px;
    z-index: 888;
    .outside-bar {
      width: 100%;
      height: 3px;
      position: absolute;
      bottom: 0;
      overflow: hidden;
    }
    .inside-bar {
      width: 0%;
      height: 100%;
      background-image: linear-gradient(
        to right,
        #fc6076,
        #ff9a44,
        #ef9d43,
        #e75516,
        #667eea,
        #764ba2,
        #6b8dd6,
        #8e37d7,
        #25aae1,
        #40e495,
        #30dd8a,
        #2bb673
      );
    }
    .loading {
      animation: bar 0.5s;
    }
    @keyframes bar {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }
    .out-box {
      width: auto;
      float: left;
      font-size: 12px;
      position: relative;
      color: #fff;
      cursor: pointer;
      height: 25px;
      text-align: center;
      border: none;
      background-size: 300% 100%;
      margin-right: 15px;
      margin-top: 7px;
      border-radius: 3px;
      line-height: 27px;
      -o-transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      transition: all 0.4s ease-in-out;
      &:hover {
        cursor: pointer;
        background-position: 100% 0;
        -o-transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
      }
      &:before {
        content: "";
        width: 6px;
        height: 6px;
        background: transparent;
        position: absolute;
        border-radius: 100%;
        left: 3px;
        top: 10px;
      }
    }
    .active {
      &:before {
        background: white !important;
      }
    }
    .popup {
      width: 100%;
      height: 100%;
      padding: 0 15px;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 3px;
    }
    .el-icon-close {
      position: absolute;
      right: 0;
      color: white;
      &:hover {
        color: red;
      }
    }
    .color-1 {
      background-image: linear-gradient(
        to right,
        #25aae1,
        #40e495,
        #30dd8a,
        #2bb673
      );
      box-shadow: 0 4px 15px 0 rgba(49, 196, 190, 0.75);
    }
    .color-2 {
      background-image: linear-gradient(
        to right,
        #f5ce62,
        #e43603,
        #fa7199,
        #e85a19
      );
      box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
    }
    .color-3 {
      background-image: linear-gradient(
        to right,
        #667eea,
        #764ba2,
        #6b8dd6,
        #8e37d7
      );
      box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
    }
    .color-4 {
      background-image: linear-gradient(
        to right,
        #fc6076,
        #ff9a44,
        #ef9d43,
        #e75516
      );
      box-shadow: 0 4px 15px 0 rgba(252, 104, 110, 0.75);
    }
    .color-5 {
      background-image: linear-gradient(
        to right,
        #0ba360,
        #3cba92,
        #30dd8a,
        #2bb673
      );
      box-shadow: 0 4px 15px 0 rgba(23, 168, 108, 0.75);
    }
    .color-6 {
      background-image: linear-gradient(
        to right,
        #009245,
        #fcee21,
        #00a8c5,
        #d9e021
      );
      box-shadow: 0 4px 15px 0 rgba(83, 176, 57, 0.75);
    }
    .color-7 {
      background-image: linear-gradient(
        to right,
        #6253e1,
        #852d91,
        #a3a1ff,
        #f24645
      );
      box-shadow: 0 4px 15px 0 rgba(126, 52, 161, 0.75);
    }
    .color-8 {
      background-image: linear-gradient(
        to right,
        #29323c,
        #485563,
        #2b5876,
        #4e4376
      );
      box-shadow: 0 4px 15px 0 rgba(45, 54, 65, 0.75);
    }
    .color-9 {
      background-image: linear-gradient(
        to right,
        #25aae1,
        #4481eb,
        #04befe,
        #3f86ed
      );
      box-shadow: 0 4px 15px 0 rgba(65, 132, 234, 0.75);
    }
    .color-10 {
      background-image: linear-gradient(
        to right,
        #ed6ea0,
        #ec8c69,
        #f7186a,
        #fbb03b
      );
      box-shadow: 0 4px 15px 0 rgba(236, 116, 149, 0.75);
    }
    .color-11 {
      background-image: linear-gradient(
        to right,
        #eb3941,
        #f15e64,
        #e14e53,
        #e2373f
      );
      box-shadow: 0 5px 15px rgba(242, 97, 103, 0.4);
    }
  }
}
</style>
